<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="main" v-if="data">
            <div class="industry atmandcns">
                <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                    <h1 class="page__subtitle">Aviation Consultancy</h1>
                </div>
                <div class="container">
                    <img class="industry__icon" src="../assets/img/about-us/icon11.svg" alt="">
                </div>

                <div class="industry__background" :style="{ backgroundImage: `url(${background2})` }">
                    <div class="container">
                        <div class="industry__block">
                            <span class="industry__text" v-html="data.upper_content"></span>
                        </div>
                    </div>
                </div>

                <div class="industry__block">
                    <div class="container">
                        <ul class="consultancy__list">
                            <li class="consultancy__list-item" v-for="item in data.categories">
                                <header class="consultancy__list-item__header">
                                    <h3>{{ item.title }}</h3>
                                    <img :src="item.img" alt="">
                                </header>

                                <span class="html_content" v-html="item.content"></span>
                                <!-- <ul class="industry__list">
                                    <li class="industry__item">Control tower design</li>
                                    <li class="industry__item">Analytica dashboards</li>
                                    <li class="industry__item">Operational concept development</li>
                                    <li class="industry__item">Procedures design</li>
                                    <li class="industry__item">Future of ATM/ATS (iCWP)</li>
                                    <li class="industry__item">ATFCM</li>
                                </ul> -->
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="aboutUs__consultancy">
                    <div class="container">
                        <span v-html="data.lower_content"></span>
                    </div>
                </div>
            </div>
        </div>

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL_PRODUCTION, BASE_URL_LOCAL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/ATMandCNS/background-1.png"),
            background2: require("@/assets/img/ATMandCNS/background-2.webp")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL_LOCAL}/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL_LOCAL}/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        // axios(`${BASE_URL_LOCAL}/page/aviation-consultancy`, {
        //     method: "GET",
        // }).then((res) => {
        //     this.data = res.data[0];
        // });

        axios(`${BASE_URL_LOCAL}/consultancy/aviation-consultancy`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data;
        });
    },
}
</script>

<style lang="scss" scoped>@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}</style>
