<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="main" v-if="data">
            <div class="industry">
                <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                    <h1 class="page__subtitle">Telecommunications</h1>
                </div>

                <div class="container">
                    <div class="industry__block">
                        <img class="industry__icon" src="../assets/img/about-us/icon2.svg" alt="">
                        <div class="industry__content">
                            <span class="html_content" v-html="data.content_1"></span>
                        </div>

                        <!-- <div class="industry__content">
                            <h2 class="industry__title">Urtica & The Future of Telecommunications</h2>
                            <p class="industry__text">In an age where connectivity reigns supreme, Urtica Smart Solutions emerges as a beacon in the vast expanse of telecommunications. Partnering with industry frontrunners, we channel expertise to refine network operations and uplift customer experiences for premier Telecom groups.</p>

                            <h2 class="industry__title">Automation - The New Pulse of Network Operations</h2>
                            <p class="industry__text">Dive into a world where network operations are efficient, streamlined, and seamlessly automated. </p>

                            <h2 class="industry__title">With Urtica:</h2>
                            <ul class="industry__list">
                                <li>Minimize OPEX & Enhance Workflow: Harness the potential of automation to diminish manual redundancies, such as advanced troubleshooting and self-healing. Experience the acceleration in operations and swift task execution.</li>
                                <li>Bridge the Silos: Our solutions propel digital transformations, merging previously isolated teams and systems. Benefit from streamlined processes that not only uplift efficiency but also foster improved business outcomes.</li>
                                <li>Maximize ROI with Precision: Urtica's approach ensures quick roll-outs and expansions, all while optimizing costs. Experience enhanced returns and elevated user experiences, thanks to meticulous network design, testing, and assurance.</li>
                            </ul>
                        </div> -->
                    </div>
                </div>

                <div class="industry__background" :style="{ backgroundImage: `url(${ background2 })` }">
                    <div class="container">
                        <div class="industry__block">
                            <span class="html_content" v-html="data.content_2"></span>
                        </div>
                        <!-- <div class="industry__block">
                            <h2 class="industry__title">Redefining Site Verification in the 5G Era</h2>
                            <p class="industry__text">Gearing up for the 5G revolution, Urtica streamlines site verification processes, ensuring:</p>
                            <ul class="industry__list">
                                <li><span style="font-weight: 700; font-size: 20px;">Efficiency & Accuracy:</span> Replace manual, error-prone methods with automated solutions. Witness a leap in the number of site verifications possible within a day.</li>
                                <li><span style="font-weight: 700; font-size: 20px;">Optimized Workflows:</span> Predictive testing and routine automation drastically curtail the time required for site verification, boosting efficiency.</li>
                                <li><span style="font-weight: 700; font-size: 20px;">Maximize Skill Utilization:</span> Automation empowers even non-specialists to conduct site verification. Witness simultaneous roll-outs, optimizing resource utilization.</li>
                                <li><span style="font-weight: 700; font-size: 20px;">Real-time Evaluation:</span> Immediate, real-time assessments of captured measurements identify and rectify issues swiftly, eliminating prolonged adjustments.</li>
                            </ul>

                            <h2 class="industry__title">Championing 5G User Experience</h2>
                            <p class="industry__text">Urtica's focus rests on delivering an unparalleled 5G user experience:</p>
                            <ul class="industry__list">
                                <li><span style="font-weight: 700; font-size: 20px;">Quality Over Quantity:</span> Rigorous testing ensures top-notch quality of experience across native and OTT applications and services.</li>
                                <li><span style="font-weight: 700; font-size: 20px;">Universal Voice Quality Testing:</span> Experience genuine voice quality without the hassle of testing every device, thanks to our network-centered approach.</li>
                                <li><span style="font-weight: 700; font-size: 20px;">Comprehensive App Experience:</span> Our pragmatic testing strategy promises results closely aligned with real-world scenarios, ensuring optimal quality ofexperience across all applications.</li>
                                <li><span style="font-weight: 700; font-size: 20px;">Flexible OTT Application Testing:</span> Urtica’s methodology eschews the need for customized OTT applications, ensuring comprehensive and accurate testing.</li>
                            </ul>

                            <h2 class="industry__title">Crafting Tomorrow's Telecom Landscape</h2>
                            <p class="industry__text">As the telecommunication landscape evolves, Urtica Smart Solutions remains committed to pioneering innovations that define the future. Partner with us and embrace aworld where communication is not just efficient but transformative.</p>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>
        

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL_PRODUCTION, BASE_URL_LOCAL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/telecommunications/background-1.webp"),
            background2: require("@/assets/img/telecommunications/background-2.webp")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL_LOCAL}/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL_LOCAL}/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        // axios(`${BASE_URL_LOCAL}/page/telecommunications`, {
        //     method: "GET",
        // }).then((res) => {
        //     this.data = res.data[0];
        // });

        axios(`${BASE_URL_LOCAL}/industries/telecommunications`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data;
        });
    },
}
</script>

<style lang="scss" scoped>@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}</style>
