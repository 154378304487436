<template>
    <div class="home">
        <AppHeader 
            v-if="header" 
            :header="header" 
        />

        <div class="aboutUs" v-if="data">
            <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${ background })` }">
            <!-- <div class="aboutUs__backgroundImage"> -->
                <h1 class="page__subtitle">About Us</h1>
            </div>

            <div class="aboutUs__industries">
                <div class="container">
                    <!-- <h1 class="home__title">{{ data.title }}</h1>
                    <span v-html="data.content" class="html_content"></span> -->
                    <p class="aboutUs__text">{{ data.header.upper_text }}</p>
                    <p class="aboutUs__text">{{ data.header.lower_text }}</p>

                    <div class="aboutUs__industries-points">
                        <p>{{ data.line_1.title }}</p>

                        <ul class="aboutUs__list">
                            <li class="aboutUs__list-item" v-for="item in data.line_1.items">
                                <img :src="item.img" alt="Иконка">
                                <p>{{ item.txt }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="aboutUs__services">
                <div class="container">
                    <div class="aboutUs__industries-points">
                        <p>{{ data.line_2.title }}</p>

                        <ul class="aboutUs__list">
                            <li class="aboutUs__list-item" v-for="item in data.line_2.items">
                                <img :src="item.img" alt="Иконка">
                                <p>{{ item.txt }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="aboutUs__consultancy">
                <div class="container">
                    <div class="aboutUs__industries-points">
                        <p>{{ data.line_3.title }}</p>

                        <ul class="aboutUs__list">
                            <li class="aboutUs__list-item" v-for="item in data.line_3.items">
                                <img :src="item.img" alt="Иконка">
                                <p>{{ item.txt }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <SmallFooter 
            v-if="footer" 
            :footer="footer" 
        />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL_PRODUCTION, BASE_URL_LOCAL } from "@/constants/constants.js"
import { mapActions } from "vuex";
import { actionTypes } from "@/store";
export default {
    name: "AboutUs",
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/about-us/about-us-background-1.jpg")
        };
    },
    created() {
        axios(`${BASE_URL_LOCAL}/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL_LOCAL}/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        // axios(`${BASE_URL_LOCAL}/page${this.$route.path}`, {
        //     method: "GET",
        // }).then((res) => {
        //     this.data = res.data[0];
        // });

        axios(`${BASE_URL_LOCAL}/about-us`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data;
        });
    },
    methods: {
        ...mapActions({
            openModal: actionTypes.getIsShowModal,
        }),
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}
</style>
