import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutUs from "../views/AboutUs.vue";
import Services from "../views/Services.vue";
import ContactUs from "../views/ContactUs.vue";
import Aviation from "../views/Aviation.vue";
import Telecommunications from "../views/Telecommunications.vue";
import Medicine from "../views/Medicine.vue";
import ATMandCNS from "../views/ATMandCNS.vue";
import EnterpriseArchitecture from "../views/EnterpriseArchitecture.vue";
import DataStrategy from "../views/DataStrategy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUs
  },
  {
    path: '/industries/aviation',
    name: 'aviation',
    component: Aviation
  },
  {
    path: '/industries/telecommunications',
    name: 'telecommunications',
    component: Telecommunications
  },
  {
    path: '/industries/medicine',
    name: 'medicine',
    component: Medicine
  },
  {
    path: '/consultancy/aviation-consultancy',
    name: 'ATMandCNS',
    component: ATMandCNS
  },
  {
    path: '/consultancy/enterprise_network_architecture',
    name: 'enterprise_network_architecture',
    component: EnterpriseArchitecture
  },
  {
    path: '/consultancy/data_strategy',
    name: 'data_strategy',
    component: DataStrategy
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs
  }
];

// enterprise_network_architecture

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
