<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="main" v-if="data">
            <div class="industry">
                <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                    <h1 class="page__subtitle">Medicine</h1>
                </div>

                <div class="container">
                    <div class="industry__block">
                        <img class="industry__icon" src="../assets/img/about-us/icon3.svg" alt="">

                        <div class="industry__content">
                            <span class="html_content" v-html="data.content_1"></span>
                            <!-- <h2 class="industry__title">Urtica & Early Cancer Detection</h2>
                            <p class="industry__text">In a world where healthcare is revolutionizing, Urtica Smart Solutions is at the forefront of medical breakthroughs, specifically focused on early cancer detection. Recognizing the pivotal role of timely diagnosis in improving patient outcomes, we merge sophisticated technology with medicine, ensuring the battle against cancer is one step ahead.</p>

                            <h2 class="industry__title">Mission to Make a Difference</h2>
                            <p class="industry__text">Our mission is simple yet transformative: Utilize routine blood tests to unearth genetic tumor biomarkers, aiming to revolutionize cancer screenings. By making them more preemptive, preventive, and economical, Urtica aspires to make these screenings accessible to all, heralding a new era of early detection and enhanced patient outcomes.</p>

                            <h2 class="industry__title">Addressing the Unmet Need</h2>
                            <p class="industry__text">The late diagnosis of cancer complications can lead to devastating consequences. Real-time monitoring during cancer treatments allows early detection of residual diseases and tumor heterogeneity. Such early interventions are crucial for long-term treatment success. Yet, challenges persist. Lengthy waits for tumor sequencing, invasive methods, exorbitant costs, and inefficiencies continue to plague the medical world. This is where Urtica steps in.</p>

                            <h2 class="industry__title">Innovating for Tomorrow</h2>
                            <p class="industry__text">Bridging the gap between need and solution, Urtica, with its groundbreaking approach, is redefining cancer monitoring. Harnessing the power of Real-Time PCR testing, combined with proprietary chemistry and algorithms, we've demonstrated our capability to scan for numerous well-known cancer mutations. And all it requires is a small blood sample. Within mere hours, our multiplexed approach delivers rapid and comprehensive mutation detections using cfDNA.</p> -->

                            <h2 class="industry__title">Benefits that Resonate</h2>
                            <div class="advantages__inner">
                                <div
                                    v-for="item in data.benefits_that_resonate"
                                    class="advantages__item"
                                    data-aos-duration="800"
                                    data-aos="fade-right"
                                >
                                    <img class="advantages__item-icon" :src="item.img" alt="Иконка" />
                                    <h6 class="advantages__item-title">{{ item.title }}</h6>
                                    <p class="advantages__item-text">{{ item.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="industry__background" :style="{ backgroundImage: `url(${background2})` }">
                    <div class="container">
                        <div class="industry__block">
                            <span class="html_content" v-html="data.content_2"></span>
                            <!-- <h2 class="industry__title">Pioneering Change in Medicine</h2>
                            <p class="industry__text">In a realm as dynamic and crucial as medicine, Urtica Smart Solutions remains committed to driving positive change. Join us in our quest to make early cancer detection the norm, not the exception, and let's redefine the future of patient care together.</p>

                            <h2 class="industry__title">Redefining Site Verification in the 5G Era</h2>
                            <p class="industry__text">Gearing up for the 5G revolution, Urtica streamlines site verification processes, ensuring:</p>
                            <ul class="industry__list">
                                <li>Efficiency & Accuracy: Replace manual, error-prone methods with automated solutions. Witness a leap in the number of site verifications possible within a day.</li>
                                <li>Optimized Workflows: Predictive testing and routine automation drastically curtail the time required for site verification, boosting efficiency.</li>
                                <li>Maximize Skill Utilization: Automation empowers even non-specialists to conduct site verification. </li>
                                <li>Real-time Evaluation: Immediate, real-time assessments of captured measurements identify and rectify issues swiftly, eliminating prolonged adjustments.</li>
                            </ul>

                            <h2 class="industry__title">Championing 5G User Experience</h2>
                            <p class="industry__text">Urtica's focus rests on delivering an unparalleled 5G user experience:</p>
                            <ul class="industry__list">
                                <li>Quality Over Quantity: Rigorous testing ensures top-notch quality of experience across native and OTT applications and services.</li>
                                <li>Universal Voice Quality Testing: Experience genuine voice quality without the hassle of testing every device, thanks to our network-centered approach.</li>
                                <li>Comprehensive App Experience: Our pragmatic testing strategy promises results closely aligned with real-world scenarios, ensuring optimal quality of experience across all applications.</li>
                                <li>Flexible OTT Application Testing: Urtica’s methodology eschews the need for customized OTT applications, ensuring comprehensive and accurate testing.</li>
                            </ul>

                            <h2 class="industry__title">Crafting Tomorrow's Telecom Landscape</h2>
                            <p class="industry__text">As the telecommunication landscape evolves, Urtica Smart Solutions remains committed to pioneering innovations that define the future. Partner with us and embrace a world where communication is not just efficient but transformative.</p> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
        

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL_PRODUCTION, BASE_URL_LOCAL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/medicine/background-1.webp"),
            background2: require("@/assets/img/medicine/background-2.jpeg")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL_LOCAL}/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL_LOCAL}/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        // axios(`${BASE_URL_LOCAL}/page/medicine`, {
        //     method: "GET",
        // }).then((res) => {
        //     this.data = res.data[0];
        // });

        axios(`${BASE_URL_LOCAL}/industries/medicine`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data;
        });
    },
}
</script>

<style lang="scss" scoped>@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}</style>
