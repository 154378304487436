<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <main v-if="footer">
            <div class="container">
                <div class="page__block" id="contacts"></div>
                <div class="container">
                    <div class="footer__contacts">
                        <div
                            class="footer__contacts-title"
                            data-aos-duration="700"
                            data-aos="fade-down"
                        >
                            {{ footer.contacts.title }}
                        </div>
                        <div class="footer__inner">
                            <div
                                class="footer__item"
                                data-aos-duration="700"
                                data-aos="fade-right"
                            >
                                <div class="footer__item-times">
                                    <p>{{ footer.contacts.times.days }}</p>
                                    <p>{{ footer.contacts.times.period }}</p>
                                </div>
                                <div class="footer__item-link">
                                    <a :href="`mailto:${footer.contacts.link.title}`">
                                        {{ footer.contacts.link.title }}
                                    </a>
                                </div>
                            </div>
                            <div
                                class="footer__item"
                                data-aos-duration="700"
                                data-aos="fade-left"
                            >
                                <div class="footer__item-address">
                                    {{ footer.contacts.address }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <div v-if="footer" class="footer">
            <div class="container">
                <div class="footer__socials">
                    <div
                        class="footer__item"
                    >
                        <div class="footer__item-copyrigth">
                            {{ footer.contacts.copyright }}
                        </div>
                    </div>
                    <div
                        class="footer__item"
                    >
                        <div class="footer__item-social">
                            <a :href="footer.contacts.social.facebook">
                                <img src="@/assets/img/icons/facebook.svg" alt="Facebook icon" />
                            </a>
                            <a :href="footer.contacts.social.twitter">
                                <img src="@/assets/img/icons/twitter.svg" alt="Twitter icon" />
                            </a>
                            <a :href="footer.contacts.social.linkedin">
                                <img src="@/assets/img/icons/linkedin.svg" alt="LinkedIn icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL_LOCAL, BASE_URL_PRODUCTION } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        AppFooter: () => import("@/components/common/AppFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL_LOCAL}/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL_LOCAL}/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });
    },
    watch: {
        '$route'(to, from) {
            axios(`${BASE_URL_LOCAL}/page${this.$route.path.replace('/industries', '')}`, {
                method: "GET",
            }).then((res) => {
                this.data = res.data[0];
            });
        }
    }
}
</script>